import React, { useEffect, useState, Suspense, useContext } from 'react'
import {Scene} from '../Scene/Scene';
import { Canvas } from '@react-three/fiber';
import { Environment } from '@react-three/drei';
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"
// import Lottie from "lottie-react"
// import devAnimation from "../../../public/animations/Animation - 1727208709979.json"

const Hero = () => {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? en : fr;

  const words = [translations['soft Eng student'], translations['web dev'], translations['comp vis ent'], translations['bi ent']];
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setText(words[index].substring(0, subIndex));

      if (!isDeleting && subIndex === words[index].length) {
        setTimeout(() => setIsDeleting(true), 50);
      } else if (isDeleting && subIndex === 0) {
        setIsDeleting(false);
        setIndex((prev) => (prev + 1) % words.length);
      }

      setSubIndex((prev) => prev + (isDeleting ? -1 : 1));
    }, Math.max(isDeleting ? 50 : 100, parseInt(Math.random() * 350)));

    return () => clearTimeout(timeout);
  }, [subIndex, isDeleting, index]);

  useEffect(() => {
    const blinkTimeout = setTimeout(() => setBlink((prev) => !prev), 50);
    return () => clearTimeout(blinkTimeout);
  }, [blink]);
  return (
        <div  id="hero" className="w-full h-[100vh] relative z-50 " >
            <div className="h-full flex lg:justify-center lg:items-center relative">
                      <div className='flex flex-col lg:justify-center text-white space-y-4 w-[90%] mt-[15vh] ml-[20px] xl:ml-[15vw] lg:mt-0'>
                        <p data-aos="fade-up" className='text-sky-800 2xl:text-4xl xl:text-3xl md:text-2xl sm:text-xl text-lg'>{translations["welcome"]}</p>
                        <h1 data-aos="fade-up" className='2xl:text-8xl lg:text-7xl text-6xl font-bold uppercase'>Talel Ayed</h1>
                        <div data-aos="fade-up" className="flex justify-start items-center text-sky-800">
                            <h1 className="2xl:text-5xl lg:text-4xl text-3xl font-bold">
                                <span id="typewriter">{text}</span>
                                <span data-aos="fade-up" className={`${blink ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}>|</span>
                            </h1>
                        </div>
                        <a data-aos="fade-up"href={language === "fr" ? "https://drive.google.com/file/d/1VJU8Kva2yPKv-nQ8ms82NS4t0d77DDzo/view?usp=sharing" : "https://drive.google.com/file/d/1B6qBgL8e81wjLmSrb23fXV0ih4keZBkY/view?usp=sharing"} className="bg-white hover:bg-gray-400 text-black text-lg font-bold py-2 px-4 rounded-2xl inline-flex items-center h-[50px] w-[200px] mt-10">
                          <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                          <span >{translations["resume"]}</span>
                        </a>
                    </div> 
                <div className="container h-full absolute w-[100vw] sm:z-999 z-[-1]">
                  {/* Hero presentation */}
                    <Canvas>
                      <Suspense fallback={null}>
                        <Scene/>
                        <Environment preset="sunset"  />
                      </Suspense>
                  </Canvas>
                </div>            
            </div>
            {/* <img src="" className='absolute right-0 bottom-0 w-full brightness-50 z-10' alt="" /> */}
            {/* <div className="absolute bottom-0 w-full z-30 bg-gradient-to-b from-transparent from-10% to-black to-90% h-[20px]  sm:h-[50px] md:[60px] "></div>         */}
            </div>
  )
}

export default Hero
